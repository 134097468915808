<template>
  <div class="content-box">
    <Title />
    <div class="title-right">
      <el-link href="https://www.100ec.cn/zt/rent/" target='_blank'>
      <img src="https://www.100ec.cn/zt/rent/img/960.gif"
    /></el-link>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item
        :index="String(index + 1)"
        v-for="(i, index) of dataNav"
        :key="index"
        @click="handleSelect(index)"
      >
        {{ i.label }}</el-menu-item
      >
    </el-menu>

    <div class="mobile-select">
      <el-select v-model="name" @change="handleSelect2">
        <el-option
          v-for="item in dataNav"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <Textlist />
  </div>
</template>

<script>
export default {
  name: "broadCast",
  data() {
    return {
      activeIndex: "1",
      name: "滚动播报",
      dataNav: [
      {
        value: "服饰",
        label: "滚动播报",
      },
      {
        value: "服装",
        label: "服装",
      },
      {
        value: "男装",
        label: "男装",
      },
      {
        value: "女装",
        label: "女装",
      },
      {
        value: "童装",
        label: "童装",
      },
      {
        value: "衣服",
        label: "衣服",
      },
      {
        value: "鞋",
        label: "鞋",
      },
      {
        value: "靴",
        label: "靴",
      },
      {
        value: "女鞋",
        label: "女鞋",
      },
      {
        value: "男鞋",
        label: "男鞋",
      },
      {
        value: "童鞋",
        label: "童鞋",
      },
      {
        value: "包",
        label: "包",
      },
      {
        value: "内衣",
        label: "内衣",
      },
      {
        value: "运动",
        label: "运动",
      },
      {
        value: "户外",
        label: "户外",
      },
      {
        value: "国潮",
        label: "国潮",
      },
      {
        value: "潮流",
        label: "潮流",
      },
      {
        value: "时尚",
        label: "时尚",
      },
      {
        value: "春装",
        label: "春装",
      },
      {
        value: "夏装",
        label: "夏装",
      },
      {
        value: "秋装",
        label: "秋装",
      },
      {
        value: "冬装",
        label: "冬装",
      },
      {
        value: "品牌 装",
        label: "品牌",
      },
      {
        value: "衣橱",
        label: "衣橱",
      }
    ],
      params: {
        json: 0,
        filter: ["【", "研报", "分析", "（PPT）", "（全文）"],
      },
    };
  },
  created() {
    this.$store.commit("textparams", this.params);
    this.$store.commit("changeKeys", this.dataNav[0].value);
    this.$store.dispatch("getText");
  },
  methods: {
    handleSelect(index) {
      this.$store.commit("changeKeys", this.dataNav[index].value);
      this.$store.dispatch("getText");
    },
    handleSelect2(value) {
      this.$store.commit("changeKeys", value);
      this.$store.dispatch("getText");
    },
  },
};
</script>
<style scoped>
.title {
  position: relative;
}
.title-right {
  position: absolute;
  left: 195px;
  top: 65px;
}
.title-right img {
  width: 580px;
  height: 70px;
}
@media (max-width: 1100px) {
  .title-right {
    position: static;
    margin: 10px 0;
  }
  .title-right img {
    width: 100%;
    height: 50px;
  }
}
</style>
